import React, { Fragment } from "react";
import { Grid, Header } from "semantic-ui-react";
import VideoPlayer from "./VideoPlayer";

const FullEmbed = () => {
  return (
    <Fragment>
      <Grid.Row stretched centered>
        <Grid.Column width={16}>
          <Header inverted>Standalone Content</Header>
        </Grid.Column>
        <Grid.Column computer={12} tablet={16}>
          <VideoPlayer />
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
};

export default FullEmbed;
