import React from 'react'

const VideoPlayer = () => {
    return (
        <div
            id="3monkeys"
            className="monkey-video-embed"
            style={{
              position: "relative",
              padding: "56.25% 0 0 0",
              height: 0,
              overflow: "hidden"
            }}
          >
            <iframe
              title="monkeyEmbed"
              src="https://main.d10t2qdz8druww.amplifyapp.com/?videoId=arenaPromo/arenaSource.m3u8&type=m3u8"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: 0,
              }}
            >
            </iframe>
          </div>
    )
}

export default VideoPlayer
