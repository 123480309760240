import React, { Fragment } from "react";
import "./App.css";
import ParagraphEmbed from "./Components/ParagraphEmbed";
import MonkeysHeader from "./Components/MonkeysHeader";
import { Grid, Segment } from "semantic-ui-react";
import FullEmbed from "./Components/FullEmbed";

function App() {
  return (
    <Fragment>
      <Segment inverted>
        <Grid>
          <MonkeysHeader />
          <ParagraphEmbed />
          <Segment basic />
          <FullEmbed />
          <Segment basic />
        </Grid>
      </Segment>
    </Fragment>
  );
}

export default App;
