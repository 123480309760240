import React from "react";
import { Grid, Header, Image } from "semantic-ui-react";
import logo from "./../Assets/YourWiseMonkeys.svg";

const MonkeysHeader = () => {
  return (
    <Grid.Row centered textAlign='center'>
        <Grid.Column width={8}>
            <Image className='monkeyLogo' fluid src={logo} />
        </Grid.Column>
        <Grid.Column width={12}>
            <Header size='huge' textAlign='center' inverted>Embed Examples</Header>
        </Grid.Column>
    </Grid.Row>
  );
};

export default MonkeysHeader;
