import React, { Fragment } from "react";
import { Grid, Header, Placeholder } from "semantic-ui-react";
import _ from "lodash";
import VideoPlayer from "./VideoPlayer";

const ParagraphEmbed = () => {
  return (
    <Fragment>
      <Grid.Row >
        <Grid.Column width={16}>
          <Header inverted>Inline in text</Header>
        </Grid.Column>
        <Grid.Column computer={8} tablet={16}>
          <Placeholder inverted fluid>
            {_.times(2, (i) => (
              <Fragment>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Fragment>
            ))}
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={8} tablet={16}>
          <VideoPlayer />
        </Grid.Column>
        <Grid.Column width={16}>
          <Placeholder inverted fluid>
            {_.times(1, (i) => (
              <Fragment>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Fragment>
            ))}
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
};

export default ParagraphEmbed;
